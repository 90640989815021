



























// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Slide } from 'vue-burger-menu'

// Store
// import { userNamespace } from '@store/user'

// Interfaces
// import { ISelect } from '@/interfaces/Interface'
// import { IUser } from '@store/user/Interface'
// import { Project } from '@store/projects/interfaces'

@Component({
  name: 'Header',

  components: {
    'v-slide': Slide,
    'v-logout': () => import('@/components/Logout.vue'),
    'v-burger-menu': () => import('@/components/BurgerMenu.vue'),
  },
})
export default class HeaderComponent extends Vue {
  // @userNamespace.State('user')
  // private user!: IUser

  // private search: string = ''
  private visibleBurgerMenu: boolean = false

  // private projectsOpt: ISelect[] = [
  //   {
  //     label: '',
  //     value: '',
  //   },
  // ]

  // private project: Project = {
  //   address: '',
  //   description: '',
  //   name: '',
  //   workListRequested: false,
  //   startDate: '',
  //   endDate: '',
  // }

  // private beforeUpdate() {
  //   this.project = this.user.projects[0]
  // }
}
